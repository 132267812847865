import React, { useState, useRef, useEffect, useLayoutEffect } from "react";
import axios from "axios";
import style from "./Request.module.scss";
import styleNew from "./applyService.module.scss";
import { useTranslation } from "react-i18next";
import {
	Row,
	Col,
	Form,
	Container,
	Tooltip,
	OverlayTrigger,
} from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import {
	saveDraftInputsData,
	servieRequestSubmit,
	saveDraftAttachmentData,
	deleteDraftAttachment,
	downloadDraftAttachment,
} from "api/serviceAPI";
import {
	setError,
	resetRequest,
	fetchServiceRequestDraft,
} from "./serviceRequestSlice";
import moment from "moment";
import {
	Attachment,
	Csv,
	Doc,
	Jpg,
	Mp3,
	Mp4,
	Pdf,
	Png,
	Text,
	Zip,
} from "components/UIComponents/FileIcons";
import { useHistory } from "react-router-dom";
import DynamicField from "components/UIComponents/Dynamics/DynamicField";
import LoadingOverlay from "components/UIComponents/LoadingOverlay";
import ProgressBar from "../../components/UIComponents/ProgressBar/ProgressBar";
import * as icons from "./icons";
import {
	makeStyles,
	StepConnector,
	StepLabel,
	withStyles,
} from "@material-ui/core";
import clsx from "clsx";
import NewClientRequestInfo from "./NewClientRequestInfo";
import SubmitModal from "./SubmitModal";
import PopUp from "components/UIComponents/PopUp/PopUp";
import { useLocation } from "react-router-dom";
import html2canvas from "html2canvas";
import Alert from "react-bootstrap/Alert";
import { useCallback } from "react";
import { messageRemoved } from "features/service/errorMessageSlice";

const QontoConnector = withStyles({
	alternativeLabel: {
		top: 10,
		left: "calc(-60% + 16px)",
		right: "calc(40% + 16px)",
	},
	active: {
		"& $line": {
			borderColor: "#58743a",
		},
	},
	completed: {
		"& $line": {
			borderColor: "#58743a",
		},
	},
	line: {
		borderColor: "#eaeaf0",
		borderWidth: 1,
		borderStyle: "dashed",
	},
})(StepConnector);

const useStyles = makeStyles((theme) => ({
	root: {
		width: "100%",
	},
	button: {
		marginRight: theme.spacing(1),
		backgroundColor: "#58743a",
		color: "white",
	},
	backButton: {
		marginRight: theme.spacing(1),
	},

	instructions: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
	step_label_root: {
		"@media (max-width:600px)": {
			fontSize: "8px",
		},
	},
}));

const useQontoStepIconStyles = makeStyles({
	root: {
		color: "#00000061",
		// color: "#eaeaf0",
		display: "flex",
		height: 22,
		alignItems: "center",
		"& path": {
			fill: "#fff",
			// fill: "#4E6B31",
		},
	},
	active: {
		color: "#3f51b5",
		// color: "#E5E9E1",
		"& path": {
			fill: "#fff",
			// fill: "#4E6B31",
		},
	},
	circle: {
		width: 50,
		height: 50,
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		borderRadius: "50%",
		backgroundColor: "currentColor",
		// border: "solid 2px white",
		// outline: "solid 3px currentColor",
		zIndex: 1,
	},
	completed: {
		width: 50,
		height: 50,
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		borderRadius: "50%",
		backgroundColor: "#4E6B31",
		// border: "solid 2px white",
		// outline: "solid 3px #4E6B31",
		zIndex: 1,
		"& path": {
			fill: "white",
		},
	},

	completedContainer: {
		position: "relative",
	},

	right: {
		position: "absolute",
		top: 0,
		right: 0,
		transform: `translate(10%, -50%)`,
	},

	step_label_root: {
		fontWeight: "bold !important",
		color: "red",
	},
	iconContainer: {
		// define styles for icon container
		transform: "scale(1.6)",
		zIndex: 9,
		color: "yellow",
	},
});

// QontoStepIcon.propTypes = {
//   /**
//    * Whether this step is active.
//    */
//   active: PropTypes.bool,
//   /**
//    * Mark the step as completed. Is passed to child components.
//    */
//   completed: PropTypes.bool,

//   icon: PropTypes.node,
// };

const TASK_SUPPORT_TYPE_ID = {
	4: "1",
	5: "2",
	15: "3",
};

const Request = (props) => {
	const { t, i18n } = useTranslation("common");
	const {
		serviceData,
		requestDate,
		inputsDataValue,
		requestId,
		inputsData,
		formsData,
		requestErrors,
		loading,
		requestFees,
		requestFiles,
		requestNotes,
	} = useSelector((state) => state.service.serviceRequest);
	const { clientProfiles } = useSelector((state) => state.user);
	const dispatch = useDispatch();
	const history = useHistory();
	const [isSubmiting, setIsSubmiting] = useState(false);
	const [acceptedTerms, setAcceptedTerms] = useState(false);
	// const [highlightTerms, setHighlightTerms] = useState(false);
	const [requestIdState, setRequestIdState] = useState(requestId);
	const [isModalShow, setIsModalShow] = useState(false);
	const termsRef = useRef();
	const [activeStep, setActiveStep] = React.useState(0);
	// const [inputsDataValuesList, setInputsDataValuesList] = React.useState([]);
	const [show, setShow] = useState(false);
	const [supportMessage, setSupportMessage] = useState("");
	const [isUploading, setIsUploading] = useState(false);
	const [stepState, setStepState] = useState({ 1: false, 2: false });
	const [alertMsg, setAlertMsg] = useState(null);
	const [stepWidth, setStepWidth] = useState(0);
	const [changesActions, setChangesActions] = useState({});
	const [resetAction, setResetAction] = useState("");
	const [img, setImg] = useState();
	const { requestData, currentTaskData, error } = useSelector(
		(state) => state.service.updateRequest
	);
	const { user } = useSelector((state) => state.user);
	const location = useLocation();
	const [validatedMsg, setValidatedMsg] = useState([]);
	const [errMsgsArr, setErrMsgsArr] = useState([]);
	const [activeServiceDetailId, setActiveServiceDetailId] = useState(null);
	const [isFocus, setIsFocus] = useState(null);

	useEffect(() => {
		if (show) {
			html2canvas(document.querySelector("#root"), {
				useCORS: true,
				allowTaint: true,
			}).then(function (canvas) {
				setImg(canvas.toDataURL("image/jpeg"));
			});
		}
	}, [show]);

	useEffect(() => {
		console.log(requestErrors)
	}, [requestErrors])

	useEffect(() => {
		let result = errMsgsArr.reduce((unique, o) => {
			if (!unique.some(obj => obj.Parameter_Name === o.Parameter_Name && obj.Errors_AR[0] === o.Errors_AR[0])) {
				unique.push(o);
			}
			return unique;
		}, []);

		// hereeeeeee
		let resultsAfterFiltering = result.filter(obj => {
			return obj.Parameter_Id != activeServiceDetailId;
		})

		// console.log(resultsAfterFiltering)
		// setValidatedMsg(result)
		setValidatedMsg(resultsAfterFiltering)
	}, [errMsgsArr, activeServiceDetailId])

	// scrolling when their are Errors Msgs
	useEffect(() => {
		if (validatedMsg.length > 0) {
			let firstVal = validatedMsg[0].Parameter_EN
			// let firstVal = Math.min(...validatedMsg.map(item => item.Parameter_Id))
			console.log(firstVal)
			console.log(validatedMsg)
			setTimeout(() => {
				if (document.getElementById(firstVal)) {
					document.getElementById(firstVal)?.scrollIntoView({ behavior: "smooth", block: "center" })
					setIsFocus(firstVal)
				} else {
					document.getElementById('alertMsg')?.scrollIntoView({ behavior: "smooth", block: "center" })
				}
			}, 2000)
		}
	}, [validatedMsg, document])

	const handlePopup = () => setShow(true);
	const handleClose = () => setShow(false);

	function QontoStepIcon(props) {
		const classes = useQontoStepIconStyles();
		const { active, completed, icon } = props;

		return (
			<div
				className={clsx(classes.root, {
					[classes.active]: active,
				})}
			>
				{completed ? (
					<div className={classes.completedContainer}>
						<div className={classes.completed}>3</div>
						<div className={classes.completed}>
							{icons.stepperIcons[icon]}
						</div>
						<span className={classes.right}>
							{stepState[icon] ? icons.right : icons.wrong}
						</span>
					</div>
				) : (
					<div className={classes.circle}>
						{icons.stepperIcons[icon]}
					</div>
				)}
			</div>
		);
	}

	const handleOnSubmit = () => {
		let parsedError;
		if (error) {
			parsedError = JSON.parse(error);
		}
		const data = new FormData();

		data.append(
			"PARAMETERS",
			JSON.stringify({
				department_Id: serviceData?.Department_Id,
				department_Service_Version_Id:
					serviceData?.Department_Service_Version_Id,
				service_Id: serviceData?.Service_Id,
				support_Request_Type_Id: currentTaskData?.Task_Type_Id
					? TASK_SUPPORT_TYPE_ID?.[currentTaskData.Task_Type_Id]
					: "",
				client_Profile_Id: clientProfiles?.Client_Profile_Id,
				client_Notes: supportMessage,
				page_Inputs_Data: JSON.stringify(inputsData),
				page_URL: location.pathname,
				error_Request_Posted_Data: JSON.stringify({
					method: parsedError?.method,
					url: parsedError?.url,
					headers: {
						Authorization: parsedError?.headers["Authorization"],
					},
				}),
				error_Request_Response_Data: JSON.stringify(parsedError?.data),
				error_Request_URL: parsedError?.url,
				error_Request_No: requestData?.Request_No,
			})
		);

		if (img) {
			setIsUploading(true);
			data.append("Page_Image", dataURItoBlob(img));
			axios
				.post(
					`${process.env.REACT_APP_API_URL}/PORTAL/CLIENTS/SUPPORTREQUEST/ADD_SUPPORT_REQUEST`,
					data,
					{
						headers: {
							Authorization: `Bearer ${user?.access_token}`,
						},
					}
				)
				.catch((err) => console.log(err))
				.finally(() => setIsUploading(false));
			setShow(false);
			setSupportMessage("");
		}
	};

	const handleTerms = () => {
		setAcceptedTerms((prev) => !prev);
	};

	const dispatchServiceRequestData = () => {
		const promise = dispatch(
			fetchServiceRequestDraft({
				Request_Draft_Id: requestId,
				Client_Profile_Id: clientProfiles?.Client_Profile_Id,
			})
		);

		return () => {
			promise.abort();
		};
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		const CancelToken = axios.CancelToken;
		const source = CancelToken.source();

		setIsSubmiting(true);
		servieRequestSubmit(
			{
				Department_Id: serviceData.Department_Id,
				Dealing_Type_Id: serviceData.Dealing_Type_Id,
				Service_Id: serviceData.Service_Id,
				Client_Profile_Id: clientProfiles.Client_Profile_Id,
				Request_Draft_Id: requestId,
			},
			source
		)
			.then((response) => {
				if (response?.Status) {
					// history.push(`/dashboard`);
					setIsModalShow(true);
					setRequestIdState(requestId);
				}
			})
			.catch((err) => {
				dispatch(setError(err));
				// dispatch(setError(null));
				dispatch(messageRemoved());
				let error = JSON.parse(err);

				let ErrAR = error?.error?.Data?.map(e => e?.Errors_AR[0]?.split(/\r?\n|\r|\n/g))
				let ErrEN = error?.error?.Data?.map(e => e?.Errors_EN[0]?.split(/\r?\n|\r|\n/g))

				setAlertMsg(
					<div>
						{i18n.language == 'ar' ? ErrAR[0]?.map(s => <p className="mb-1">{s}</p>) : ErrEN[0]?.map(s => <p className="mb-1">{s}</p>)}
					</div>
				);
			})
			.finally(() => {
				setIsSubmiting(false);
			});

		return () => {
			source.cancel();
		};
	};

	const handleModalClick = () => {
		setIsModalShow(false);
	};

	const handleDefaultValue = (item) => {
		if (inputsDataValue?.length) {
			const dataValue = inputsDataValue.filter(
				(dataValue) =>
					dataValue?.Department_Service_Detail_Id ===
					item.Department_Service_Detail_Id
			);
			if (dataValue && resetAction === "")
				return dataValue[0]?.Service_Detail_Value;
			else {
				return null;
			}
		}
		return null;
	};

	const handleValidationMessage = (item) => {
		if (inputsDataValue?.length) {
			const dataValue = inputsDataValue.filter(
				(dataValue) =>
					dataValue?.Department_Service_Detail_Id ===
					item.Department_Service_Detail_Id
			);

			if (dataValue) return dataValue[0];
			else {
				return null;
			}
		}
		return null;
	};

	const handleSupportChange = (e) => {
		setSupportMessage(e.target.value);
	};

	const handleInputsDataValues = useCallback((item) => {
		if (inputsDataValue?.length) {
			const dataValue = inputsDataValue.filter(
				(dataValue) =>
					dataValue?.Department_Service_Detail_Id ===
					item.Department_Service_Detail_Id
			);

			if (dataValue) return dataValue[0];
			else {
				return null;
			}
		}
	}, []);

	const handleFileValue = (item) => {
		if (inputsDataValue?.length) {
			const fileValue = inputsDataValue.filter(
				(fileValue) =>
					fileValue?.Department_Service_Detail_Id ===
					item.Department_Service_Detail_Id
			);

			if (fileValue) return fileValue[0]?.Files_Data;
			else {
				return null;
			}
		}
		return null;
	};

	const handleSave = () => {
		history?.push(`/dashboard`);
	};

	const onInputSave = async (e, item, isDate) => {
		let newArr = validatedMsg.filter(err => err?.Parameter_Id != item?.Service_Detail_Id);
		setErrMsgsArr(newArr)

		await saveDraftInputsData({
			Client_Profile_Id: clientProfiles?.Client_Profile_Id,
			Request_Draft_Id: requestId,
			Request_Draft_Data: [
				{
					Service_Detail_Id: item.Service_Detail_Id,
					Service_Detail_Value: item?.Department_Service_Detail_Type_Id == 6 && e?.target?.value !== "" ?
						moment(e?.target?.value, "DD/MM/YYYY").lang("en").format("YYYY-MM-DD") : item?.Department_Service_Detail_Type_Id == 8 ?
							e?.target?.value?.replace(/\s/g, '') : e.target.value,
				},
			],
		})
			.then((data) => setChangesActions(data?.Data?.Changes_Actions))
			.catch((err) => {
				dispatch(messageRemoved())
				setErrMsgsArr(oldArray => [...oldArray, JSON.parse(err)?.error?.Data[0]])
			})
	};

	const classes = useQontoStepIconStyles();

	function getSteps() {
		let stepsArr = [];
		formsData?.map((step) => {
			stepsArr.push(
				<StepLabel
					classes={{
						active: classes.step_label_root,
						completed: classes.step_label_root,
						iconContainer: classes.iconContainer,
					}}
				>
					{i18n.language == "ar"
						? step.Department_Service_Detail_Form_Title_AR
						: step.Department_Service_Detail_Form_Title_EN}
				</StepLabel>
			);
		});

		stepsArr.push(
			<StepLabel
				StepIconComponent={QontoStepIcon}
				classes={{
					active: classes.step_label_root,
					completed: classes.step_label_root,
					// iconContainer: classes.iconContainer
				}}
			>
				{t("data_review")}
			</StepLabel>
		);

		return stepsArr;
	}

	const steps = getSteps();

	const handleNext = async () => {
		setIsUploading(true);
		dispatchServiceRequestData();
		setResetAction("");
		alertMsg && setAlertMsg(null);

		await axios.post(`${process.env.REACT_APP_API_URL}/PORTAL/CLIENTS/REQUESTS/VALIDATE_DRAFT_FORM_INPUTS_DATA`,
			{
				Client_Profile_Id: clientProfiles?.Client_Profile_Id,
				Request_Draft_Id: requestId,
				Department_Service_Detail_Form_Id:
					formsData[activeStep]
						?.Department_Service_Detail_Form_Id,
			},
			{
				headers: {
					Authorization: `Bearer ${user?.access_token}`,
				},
			}
		).then((res) => {
			setStepState((prev) => ({
				...prev,
				[activeStep + 1]:
					res.data.Data === "Inputs Valid" ? true : false,
			}));

			if (res.data.Data === "Inputs Valid") {
				setActiveStep((prevActiveStep) => prevActiveStep + 1);
				window.scrollTo(0, 400)
			} else {
				res?.data?.Data?.map(res => setErrMsgsArr(oldArray => [...oldArray, res]))
				let vMsgs = res?.data?.Data.filter(item1 =>
					!inputsData.some(item2 => (item2?.Service_Detail_Id == item1?.Parameter_Id)))
				vMsgs.length > 0 && setAlertMsg(vMsgs)
			}
		}).finally(() => {
			setIsUploading(false)
		})
	};

	const handleBack = () => {
		if (alertMsg) {
			setAlertMsg(null);
		}
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
		window.scrollTo(0, 400)
		dispatchServiceRequestData();
		setResetAction("");
	};

	const handleReset = () => {
		setActiveStep(0);
	};

	function dataURItoBlob(dataURI) {
		// convert base64 to raw binary data held in a string
		// doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
		var byteString = atob(dataURI.split(",")[1]);

		// separate out the mime component
		var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

		// write the bytes of the string to an ArrayBuffer
		var ab = new ArrayBuffer(byteString.length);

		// create a view into the buffer
		var ia = new Uint8Array(ab);

		// set the bytes of the buffer to the correct values
		for (var i = 0; i < byteString.length; i++) {
			ia[i] = byteString.charCodeAt(i);
		}

		// write the ArrayBuffer to a blob, and you're done
		var blob = new Blob([ab], { type: mimeString });
		return blob;
	}

	const handleResetValCallback = (childData) => {
		setResetAction(childData);
		setActiveServiceDetailId(childData[0]?.Service_Detail_Id)
	};

	const saveImageToLocal = data => {
		let newArr = validatedMsg.filter(err => err?.Parameter_Id != data);
		setErrMsgsArr(newArr)
	}

	const onFileChange = data => {
		let newArr = validatedMsg.filter(err => err?.Parameter_Id != data);
		setErrMsgsArr(newArr)
	}

	const handleInputsdata = (inputsData) => {
		if (inputsData?.length > 0) {
			let data = [...inputsData].sort(
				(a, b) =>
					Number(a.Department_Service_Detail_No) -
					Number(b.Department_Service_Detail_No)
			);
			let stepsInputs = data
				?.filter(
					(item) =>
						item.Department_Service_Detail_Form_No == activeStep + 1
				)
				?.map((item) => {
					if (item.Department_Service_Detail_Type_Id != 3) {
						return (
							<Col
								key={item.Department_Service_Detail_Id}
								md={item?.Department_Service_Detail_Width}
								className="mt-5"
							>
								<DynamicField
									key={item.Department_Service_Detail_Id}
									defaultValue={() => handleDefaultValue(item)}
									validationMessage={handleValidationMessage(item)}
									onSave={onInputSave}
									item={item}
									inputsData={() => handleInputsDataValues(item)}
									autoSave={item.Department_Service_Detail_Type_Id === 10}
									changesActions={changesActions}
									handleResetValCallback={handleResetValCallback}
									resetAction={resetAction}
									// validatedMsg={errMsgsArr}
									validatedMsg={validatedMsg}
									isFocus={isFocus}
								/>
							</Col>
						);
					} else {
						return (
							<Col
								md={item?.Department_Service_Detail_Width}
								className={[style.step, 'mt-auto'].join(' ')}
							>
								<FileUpload
									key={item.Department_Service_Detail_Id}
									defaultValue={() => handleFileValue(item)}
									item={item}
									// validatedMsg={errMsgsArr}
									validatedMsg={validatedMsg}
									onFileValueChange={saveImageToLocal}
									onFileValueChange2={onFileChange}
								/>
							</Col>
						);
					}
				});
			return stepsInputs;
		}
	};

	const scroll = React.useCallback((node) => {
		if (node) {
			window.scrollTo({
				top: node.getBoundingClientRect().top,
				behavior: "smooth",
			});
		}
	}, []);

	useEffect(() => {
		let totalSteps = steps?.length;
		let stepWidth = 100 / totalSteps;
		let currentStep = activeStep + 1;
		let width = stepWidth * currentStep;
		setStepWidth(`${width}%`);
	}, [activeStep, steps]);

	useEffect(() => {
		setValidatedMsg([])
	}, [activeStep])

	return (
		<>
			{/* New Header */}
			<Row>
				<Col md={12}>
					<div
						className={[styleNew.card, "gCard", "p-3"].join(" ")}
						ref={scroll}
					>
						<Row>
							<Col
								md={3}
								className="d-flex justify-content-center  align-items-center"
							>
								<div className={styleNew.serviceInfo}>
									{t("Service Information")}
								</div>
							</Col>

							<Col md={5}>
								{/* 1 */}
								<div className="d-flex justify-content-between mb-2">
									<div className="w-50">
										<span className={styleNew.infoTitle}>
											{t("drafts_number")}
										</span>
									</div>
									<div className="w-50">
										<span className={styleNew.infoData}>
											{requestId}
										</span>
									</div>
								</div>
								{/* 2 */}
								<div className="d-flex justify-content-between mb-2">
									<div className="w-50">
										<span className={styleNew.infoTitle}>
											{t("Draft Date")}
										</span>
									</div>
									<div className="w-50">
										<span className={styleNew.infoData}>
											{requestDate &&
												moment(requestDate)
													.lang("en")
													.format("DD-MM-YYYY")}
										</span>
									</div>
								</div>
								{/* 3*/}
								<div className="d-flex justify-content-between mb-2">
									<div className="w-50">
										<span className={styleNew.infoTitle}>
											{t("department_name")}
										</span>
									</div>
									<div className="w-50">
										<span className={styleNew.infoData}>
											{i18n.language === "ar"
												? serviceData?.Department_Name_AR
												: serviceData?.Department_Name_EN}
										</span>
									</div>
								</div>
								{/* 4 */}
								<div className="d-flex justify-content-between mb-2">
									<div className="w-50">
										<span className={styleNew.infoTitle}>
											{t("Service Version")}
										</span>
									</div>
									<div className="w-50">
										<span className={styleNew.infoData}>
											{
												serviceData?.Department_Service_Version_Name
											}
										</span>
									</div>
								</div>
							</Col>

							<Col md={4} className="d-flex align-items-end">
								<div className={styleNew.mediaCont}>
									<div className={styleNew.infoData}>
										{t("menu_municipality_of_sharjah")}
									</div>
									<div className={styleNew.subTitle}>
										{i18n.language === "ar"
											? serviceData?.Department_Section_Name_AR
											: serviceData?.Department_Section_Name_EN}
									</div>
									<div
										className={`d-flex justify-content-between align-items-center ${styleNew.w90}`}
									>
										{serviceData?.Department_Youtube &&
											<a
												href={/(http(s?)):\/\//i.test(serviceData?.Department_Youtube) ? serviceData?.Department_Youtube : `https://${serviceData?.Department_Youtube}`}
												target="_blank"
											>
												{icons.youtube}
											</a>
										}

										{serviceData?.Department_Instagram &&
											<a
												href={/(http(s?)):\/\//i.test(serviceData?.Department_Instagram) ? serviceData?.Department_Instagram : `https://${serviceData?.Department_Instagram}`}
												target="_blank"
											>
												{icons.instagarmIcon}
											</a>
										}

										{serviceData?.Department_LinkedIn &&
											<a
												href={/(http(s?)):\/\//i.test(serviceData?.Department_LinkedIn) ? serviceData?.Department_LinkedIn : `https://${serviceData?.Department_LinkedIn}`}
												target="_blank"
											>
												{icons.linkedin}
											</a>
										}

										{serviceData?.Department_Twitter &&
											<a
												href={/(http(s?)):\/\//i.test(serviceData?.Department_Twitter) ? serviceData?.Department_Twitter : `https://${serviceData?.Department_Twitter}`}
												target="_blank"
											>
												{icons.twitter}
											</a>
										}

										{serviceData?.Department_Facebook &&
											<a
												href={/(http(s?)):\/\//i.test(serviceData?.Department_Facebook) ? serviceData?.Department_Facebook : `https://${serviceData?.Department_Facebook}`}
												target="_blank"
											>
												{icons.facebookIcon}
											</a>
										}

										{serviceData?.Department_Location &&
											<a
												href={/(http(s?)):\/\//i.test(serviceData?.Department_Location) ? serviceData?.Department_Location : `https://${serviceData?.Department_Location}`}
												target="_blank"
											>
												{icons.locationIcon}
											</a>
										}

										{serviceData?.Department_Whatsapp &&
											<a
												href={`https://api.whatsapp.com/send?phone=${serviceData?.Department_Whatsapp}`}
												target="_blank"
											>
												{icons.whatsapp}
											</a>
										}

										{serviceData?.Department_Telephone &&
											window.screen.width >= 1280 ?
											<OverlayTrigger
												overlay={
													<Tooltip id="button-tooltip">
														{serviceData?.Department_Telephone}
													</Tooltip>
												}
											>
												{icons.phoneIcon}
											</OverlayTrigger>
											:
											<a href={`tel: ${serviceData?.Department_Telephone}`}>
												{icons.phoneIcon}
											</a>
										}
									</div>
								</div>
							</Col>

							{/* <Col className={styleNew.technicalSupportContainer} md={2}>
                <button
                  onClick={handlePopup}
                  className={`mainBtn primarymainBtn p-2 ${styleNew.technicalSupportButton}`}
                >
                  {t("technicalSupport_request")}
                </button>
              </Col> */}
						</Row>
					</div>
				</Col>
			</Row>
			{/* New Header End */}

			{requestErrors?.Error_AR &&
				<div className={styleNew.requestWarning}>
					{i18n.language == 'ar' ? <p>{requestErrors?.Error_AR}</p> : <p>{requestErrors?.Error_EN}</p>}
				</div>
			}

			<div className={[styleNew.formBorder, 'mt-5'].join(' ')}>
				<Form onSubmit={handleSubmit}>
					<div
						className={`${styleNew.nextPreviousContainer} mt-5 mb-5`}
					>
						<div className={styleNew.previousBtnWrapper}>
							{activeStep > 0 && (
								<button
									type="button"
									onClick={handleBack}
									className={`mainBtn ${styleNew.stepsButton}`}
								>
									{t("previous")}
								</button>
							)}
							{/* {activeStep > 0 && (
                <button
                  type="button"
                  onClick={handleBack}
                  className={`mainBtn ${styleNew.stepsButton}`}
                >
                  {t("previous")}
                </button>
              )} */}
						</div>
						<div className="d-flex flex-wrap justify-content-center justify-content-sm-start flex-sm-nowrap flex-column flex-md-row align-items-center">
							<button
								type={"button"}
								onClick={handleSave}
								className={`mainBtn mx-3 mb-2 mb-md-0 ${styleNew.saveButton}`}
							>
								{t("save")}
							</button>
							{activeStep < steps.length - 1 && (
								<button
									type={"button"}
									onClick={handleNext}
									className={`mainBtn mx-2 ${styleNew.stepsButton}`}
								>
									{t("next")}
								</button>
							)}
							{acceptedTerms &&
								activeStep === steps.length - 1 && (
									<button
										type={"submit"}
										className={`mainBtn ${styleNew.stepsButton}`}
									>
										{t("submitService")}
									</button>
								)}
						</div>
					</div>

					<div>
						<div className="d-flex align-items-center justify-content-between">
							<p className={style.serviceName}>
								{i18n.language == "en"
									? serviceData?.Service_Name_EN
									: serviceData?.Service_Name_AR}
							</p>
							<span>{icons.paper}</span>
						</div>

						<div className={style.bar}>
							{stepWidth == "100%" && activeStep == 0 ? (
								<></>
							) : (
								<div style={{ width: stepWidth }}></div>
							)}
						</div>

						<div className="d-flex align-items-center justify-content-between">
							<div className={style.stepName}>
								{t("Step_")} {i18n.language == 'ar' ? (activeStep + 1).toLocaleString('ar-u-nu-arab') : activeStep + 1}{" "}
								{steps[activeStep]?.props?.children}
							</div>
							<div className={style.stepNo}>
								{t("Step_")} {i18n.language == 'ar' ? (activeStep + 1).toLocaleString('ar-u-nu-arab') : activeStep + 1}{" "}
								<span className={style.totalSteps}>
									{t("of")} {i18n.language == 'ar' ? (steps?.length).toLocaleString('ar-u-nu-arab') : steps?.length}
								</span>
							</div>
						</div>
					</div>

					<Row>
						<Col xl={12} className={style.step}>
							<div>
								{loading !== "pending" ? (
									<Row>
										{steps.length - 1 == activeStep ? (
											<NewClientRequestInfo
												inputsData={inputsData}
												inputsDataValue={
													inputsDataValue
												}
												requestFees={requestFees}
												requestFiles={requestFiles}
												requestNotes={requestNotes}
												steps={steps?.slice(0, -1)}
											/>
										) : (
											handleInputsdata(inputsData)
										)}
									</Row>
								) : (
									// <div>{t("Loading")}</div>
									<LoadingOverlay />
								)}
							</div>
						</Col>

						{/* Errors Msgs Box */}
						<Col md={12} className="mt-5">
							<Alert
								show={!!alertMsg}
								variant={"danger"}
								className="d-flex justify-content-between align-items-center"
								transition={true}
								id='alertMsg'
							>
								{/* {alertMsg} */}
								<div>
									{Array.isArray(alertMsg) ?
										alertMsg?.map(err => i18n.language == 'ar' ? err?.Errors_AR?.map(e => <p className='mb-1'>{e}</p>) : err?.Errors_EN?.map(e => <p className='mb-1'>{e}</p>))
										: alertMsg}
								</div>

								<span
									className={style.dismiss}
									onClick={() => setAlertMsg(null)}
								>
									<svg
										height="311pt"
										viewBox="0 0 311 311.07733"
										width="311pt"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path d="m16.035156 311.078125c-4.097656 0-8.195312-1.558594-11.308594-4.695313-6.25-6.25-6.25-16.382812 0-22.632812l279.0625-279.0625c6.25-6.25 16.382813-6.25 22.632813 0s6.25 16.382812 0 22.636719l-279.058594 279.058593c-3.136719 3.117188-7.234375 4.695313-11.328125 4.695313zm0 0" />
										<path d="m295.117188 311.078125c-4.097657 0-8.191407-1.558594-11.308594-4.695313l-279.082032-279.058593c-6.25-6.253907-6.25-16.386719 0-22.636719s16.382813-6.25 22.636719 0l279.058594 279.0625c6.25 6.25 6.25 16.382812 0 22.632812-3.136719 3.117188-7.230469 4.695313-11.304687 4.695313zm0 0" />
									</svg>
								</span>
							</Alert>
						</Col>
					</Row>

					{steps.length - 1 == activeStep && (
						<Col xl={12} className={style.step}>
							<div className={[style.terms, 'flex-row'].join(' ')}>
								<input
									className={style.checkBox}
									type="checkbox"
									defaultChecked={acceptedTerms}
									onChange={handleTerms}
								/>
								{/* <p ref={termsRef}>
									{t("terms and conditions")}
								</p>
							</div> */}

								<div
									className={style.terms}
									dangerouslySetInnerHTML={{
										__html:
											i18n.language === "ar"
												? serviceData?.Service_Rules_AR
												: serviceData?.Service_Rules_EN,
									}}
								></div>
							</div>
						</Col>
					)}

					<div
						className={`${styleNew.nextPreviousContainer} mt-5 mb-5`}
					>
						<div className={styleNew.previousBtnWrapper}>
							{activeStep > 0 && (
								<button
									type="button"
									onClick={handleBack}
									className={`mainBtn ${styleNew.stepsButton}`}
								>
									{t("previous")}
								</button>
							)}
							{/* {activeStep > 0 && (
                <button
                  type="button"
                  onClick={handleBack}
                  className={`mainBtn ${styleNew.stepsButton}`}
                >
                  {t("previous")}
                </button>
              )} */}
						</div>
						<div className="d-flex flex-wrap justify-content-center justify-content-sm-start flex-sm-nowrap flex-column flex-md-row align-items-center">
							<button
								type={"button"}
								onClick={handleSave}
								className={`mainBtn mx-3 mb-2 mb-md-0 ${styleNew.saveButton}`}
							>
								{t("save")}
							</button>
							{activeStep < steps.length - 1 && (
								<button
									type={"button"}
									onClick={handleNext}
									className={`mainBtn mx-2 ${styleNew.stepsButton}`}
								>
									{t("next")}
								</button>
							)}
							{acceptedTerms &&
								activeStep === steps.length - 1 && (
									<button
										type={"submit"}
										className={`mainBtn ${styleNew.stepsButton}`}
									>
										{t("submitService")}
									</button>
								)}
						</div>
					</div>

					<SubmitModal
						isShow={isModalShow}
						handleClick={handleModalClick}
					/>

					<PopUp
						show={show}
						handleClose={handleClose}
						title={t("technical_support_request")}
						smallModal={true}
					>
						<Container>
							<Row>
								<Col>
									<label className={styleNew.label}>
										{t("notes")}
									</label>
									<Form.Control
										onChange={handleSupportChange}
										value={supportMessage}
										name="client_notes"
										as="textarea"
									/>
								</Col>
							</Row>
							<Row className="mt-2">
								<Col className="d-flex justify-content-end">
									<button
										onClick={handleOnSubmit}
										className={[
											"mainBtn primarymainBtn",
											styleNew.tSupportBtn,
										].join(" ")}
									>
										{t("confirm_technical_support_request")}
									</button>
								</Col>
							</Row>
						</Container>
					</PopUp>
				</Form>
			</div>
			{isSubmiting && <LoadingOverlay />}
			{isUploading && <LoadingOverlay />}
		</>
	);
};

export default Request;

const FileUpload = ({ item, defaultValue, validatedMsg, onFileValueChange, onFileValueChange2 }) => {
	const { t, i18n } = useTranslation("common");
	const [uploadedFiles, setUploadedFiles] = useState(
		defaultValue ? defaultValue : []
	);
	const { clientProfiles } = useSelector((state) => state.user);
	const { requestId } = useSelector((state) => state.service.serviceRequest);
	const dispatch = useDispatch();
	const [isUploading, setIsUploading] = useState(false);
	const [fileUploadingData, setFileUploadingData] = useState({});
	const [isEllipsis, setIsEllipsis] = useState(true);
	const [progressValue, setProgressValue] = useState(0);
	const [isTextExpanded, setIsTextExpanded] = React.useState(false);
	const [alertMsg, setAlertMsg] = useState(false);
	const [validMsg, setValidMsg] = useState([]);
	const [showModal, setShowModal] = useState(false);
	const canvasRef = useRef(null);
	const contextRef = useRef(null);
	let context, canvas;
	const [isDrawing, setIsDrawing] = useState(false);
	const [showErrorMsg, setShowErrorMsg] = useState(true);

	useEffect(() => {
		canvas = canvasRef?.current;
		context = canvas?.getContext("2d");
	}, [showModal]);

	useEffect(() => {
		if (canvas != null && context != null) {
			canvas.width = 400;
			canvas.height = 300;
			context.strokeStyle = "black";
			context.lineWidth = 1;
			contextRef.current = context;
		}
	}, [canvas, context, showModal]);

	const startDrawing = ({ nativeEvent }) => {
		const { offsetX, offsetY } = nativeEvent;
		contextRef.current.beginPath();
		contextRef.current.moveTo(offsetX, offsetY);
		contextRef.current.lineTo(offsetX, offsetY);
		contextRef.current.stroke();
		setIsDrawing(true);
		nativeEvent.preventDefault();
	};

	const draw = ({ nativeEvent }) => {
		if (!isDrawing) {
			return;
		}
		const { offsetX, offsetY } = nativeEvent;
		contextRef.current.lineTo(offsetX, offsetY);
		contextRef.current.stroke();
		nativeEvent.preventDefault();
	};

	const stopDrawing = () => {
		contextRef.current.closePath();
		setIsDrawing(false);
	};

	const setToErase = () => {
		let x = canvasRef?.current?.getContext("2d");
		x?.save();
		x?.setTransform(1, 0, 0, 1, 0, 0);
		x?.clearRect(
			0,
			0,
			canvasRef?.current?.width,
			canvasRef?.current?.height
		);
		x?.restore();
	};

	function dataURLtoFile(dataurl, filename) {
		var arr = dataurl.split(","),
			mime = arr[0].match(/:(.*?);/)[1],
			bstr = atob(arr[arr.length - 1]),
			n = bstr.length,
			u8arr = new Uint8Array(n);
		while (n--) {
			u8arr[n] = bstr.charCodeAt(n);
		}
		return new File([u8arr], filename, { type: mime });
	}

	const saveImageToLocal = async (event) => {
		let image = canvasRef.current.toDataURL("image/png");
		let file = dataURLtoFile(image, "signature.png");
		setShowModal(false);
		onFileValueChange(item.Service_Detail_Id)
		setValidMsg(null)

		if (file) {
			const data = new FormData();

			data.append(
				"PARAMETERS",
				JSON.stringify({
					Client_Profile_Id: clientProfiles?.Client_Profile_Id,
					Request_Draft_Id: requestId,
					Service_Detail_Id: item.Service_Detail_Id,
				})
			);

			setIsUploading(true);

			data.append("Attached_File", file);

			const response = await saveDraftAttachmentData(data, handleProgress)
				.catch((err) => {
					let error = JSON.parse(err);
					// dispatch(setError(err));
					dispatch(messageRemoved());
					setValidMsg(error?.error?.Data);
				})
				.finally(() => {
					setIsUploading(false);
					setProgressValue(0);
				});
			if (response?.Status) {
				setUploadedFiles(response?.Data?.Files_Data);
			}
		}

		setShowErrorMsg(false)
	};

	const onChange = async (e) => {
		var files = e.target.files[0];
		onFileValueChange2(item.Service_Detail_Id)
		setValidMsg([])

		if (
			item?.Department_Service_File_Max_Size != 0 &&
			e?.target?.files[0]?.size > 10485760
		) {
			setAlertMsg(true);
			e.target.value = null;
		} else {
			if (files) {
				const data = new FormData();

				data.append(
					"PARAMETERS",
					JSON.stringify({
						Client_Profile_Id: clientProfiles?.Client_Profile_Id,
						Request_Draft_Id: requestId,
						Service_Detail_Id: item.Service_Detail_Id,
					})
				);

				setFileUploadingData({
					name: e?.target?.files[0]?.name,
					size: (e?.target?.files[0]?.size / (1024 * 1024)).toFixed(
						2
					),
					type: e?.target?.files[0]?.type,
				});
				setIsUploading(true);
				data.append("Attached_File", e.target.files[0]);

				const response = await saveDraftAttachmentData(
					data,
					handleProgress
				)
					.catch((err) => {
						let error = JSON.parse(err);
						// dispatch(setError(err));
						dispatch(messageRemoved());
						if (Array.isArray(error?.error?.Data)) {
							setValidMsg(error?.error?.Data);
						} else {
							setValidMsg(i18n.language == 'ar' ? error?.error?.Error_AR : error?.error?.Error_EN);
						}
					})
					.finally(() => {
						setIsUploading(false);
						setFileUploadingData({});
						setProgressValue(0);
					});
				if (response?.Status) {
					setUploadedFiles(response?.Data?.Files_Data);
				}
				e.target.value = null;
			}
		}

		setShowErrorMsg(false)
	};

	const removeFile = async (f) => {
		const response = await deleteDraftAttachment({
			Client_Profile_Id: clientProfiles?.Client_Profile_Id,
			Request_Draft_Id: requestId,
			Service_Detail_Id: item.Service_Detail_Id,
			File_Code: f.File_Code,
		});
		if (response?.Status) {
			setUploadedFiles((prev) => prev.filter((x) => x !== f));
		}
	};

	const download = async (f) => {
		await downloadDraftAttachment({
			Client_Profile_Id: clientProfiles?.Client_Profile_Id,
			Request_Draft_Id: requestId,
			Service_Detail_Id: item.Service_Detail_Id,
			File_Code: f.File_Code,
		})
			.then(function (response) {
				const url = window.URL.createObjectURL(new Blob([response]));
				const link = document.createElement("a");
				link.href = url;
				link.setAttribute("download", f.File_Name);
				document.body.appendChild(link);
				link.click();
			})
			.catch((ex) => {
				console.error(ex);
			});
	};

	const handleToggleEllipsis = () => {
		// setIsEllipsis(!isEllipsis);
		setIsTextExpanded((prev) => !prev);
	};

	const handleProgress = (progress) => {
		var progressValue = (progress.loaded / progress.total) * 100;
		setProgressValue(Math.trunc(progressValue));
	};

	const handleExpandLabel = (setState, title, ref) => {
		setIsTextExpanded((prev) => !prev);
	};

	const handleClose = () => setShowModal(false);

	return (
		<div>
			<Col key={item.Department_Service_Detail_Id} xs={12} id={item.Department_Service_Detail_Title_EN}>
				<div>
					<div className={styleNew.fileUploaderCont}>
						<div className={styleNew.labelCont}>
							<span>
								<svg
									width="48"
									height="48"
									viewBox="0 0 48 48"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M32 32L24 24L16 32"
										stroke="black"
										strokeOpacity="0.4"
										strokeWidth="2"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path
										d="M24 24V42"
										stroke="black"
										strokeOpacity="0.4"
										strokeWidth="2"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path
										d="M40.7789 36.78C42.7296 35.7166 44.2706 34.0338 45.1587 31.9973C46.0468 29.9608 46.2314 27.6865 45.6834 25.5334C45.1353 23.3803 43.8859 21.4711 42.1323 20.1069C40.3786 18.7428 38.2207 18.0015 35.9989 18H33.4789C32.8736 15.6585 31.7453 13.4847 30.1788 11.642C28.6124 9.79933 26.6486 8.33573 24.4351 7.36124C22.2216 6.38676 19.816 5.92675 17.3992 6.01579C14.9823 6.10484 12.6171 6.74063 10.4813 7.87536C8.34552 9.01009 6.49477 10.6142 5.06819 12.5672C3.64161 14.5202 2.67632 16.7711 2.2449 19.1508C1.81348 21.5305 1.92715 23.9771 2.57737 26.3066C3.22759 28.636 4.39743 30.7878 5.99894 32.6"
										stroke="black"
										strokeOpacity="0.4"
										strokeWidth="2"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path
										d="M32 32L24 24L16 32"
										stroke="black"
										strokeOpacity="0.4"
										strokeWidth="2"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
								</svg>
							</span>

							<div>
								{item.Department_Service_Detail_Required ? (
									<Form.Label
										onClick={handleExpandLabel}
										className={[
											isTextExpanded
												? styleNew.textLabel
												: styleNew.textExpandedLabel,
											"mb-0",
										].join(" ")}
									>
										{i18n.language === "ar"
											? item?.Department_Service_Detail_Title_AR
											: item?.Department_Service_Detail_Title_EN}
										<span className={style.required}>
											*
										</span>
									</Form.Label>
								) : (
									<Form.Label
										onClick={handleExpandLabel}
										className={[
											isTextExpanded
												? styleNew.textLabel
												: styleNew.textExpandedLabel,
											"mb-0",
										].join(" ")}
									>
										{i18n.language === "ar"
											? item?.Department_Service_Detail_Title_AR
											: item?.Department_Service_Detail_Title_EN}
									</Form.Label>
								)}
								<Form.Text className={styleNew.disclaimr}>
									<span>{t("Required File Format")}</span>
									{i18n.language === "ar"
										? item.File_Content_Type_Name_AR
										: item.File_Content_Type_Name_EN}
								</Form.Text>
								{item?.Department_Service_File_Max_Count == 1 &&
									item.Department_Service_File_Signature ==
									1 && (
										<Form.Text
											className={styleNew.disclaimr}
										>
											<span>{t("fileMaxCount")}</span>
										</Form.Text>
									)}
								{item.Department_Service_File_Signature ==
									0 && (
										<Form.Text className={styleNew.disclaimr}>
											<span>
												{" "}
												{t("fileMaxNo")}
												{
													item?.Department_Service_File_Max_Count
												}
											</span>
										</Form.Text>
									)}
								{item?.Department_Service_File_Max_Size !=
									0 && (
										<Form.Text className={styleNew.disclaimr}>
											<span>{t("fileMaxSizeDynamic")} {item?.Department_Service_File_Max_Size} {t('fileMaxSizeDynamicMB')}</span>
										</Form.Text>
									)}
							</div>

							<div
								className={[
									styleNew.fileButton,
									i18n.language == "en"
										? styleNew.fileBtnEN
										: styleNew.fileBtnAR,
								].join(" ")}
							>
								<label className={styleNew.fileContainer}>
									{item.Department_Service_File_Signature ==
										1 &&
										item.Department_Service_File_Signature_Attachment ==
										0 ? (
										<button
											type="button"
											className={styleNew.signatureBtn}
											onClick={() => setShowModal(true)}
										>
											<div>{t("signature")}</div>
											<div>{icons.pencil}</div>
										</button>
									) : item.Department_Service_File_Signature ==
										1 &&
										item.Department_Service_File_Signature_Attachment ==
										1 ? (
										<div
											className={
												styleNew.signatureUploadBtn
											}
										>
											<button
												type="button"
												className={
													styleNew.signatureIcon
												}
												onClick={() =>
													setShowModal(true)
												}
											>
												<div>{icons.pencil}</div>
											</button>
											<button
												type="button"
												className={styleNew.uploadIcon}
											>
												<div>{icons.upload}</div>
											</button>
										</div>
									) : (
										<button
											className={styleNew.fileButton}
											type="button"
										>
											<div>{t("chooseFile")}</div>
											<div>{icons.upload}</div>
										</button>
									)}
								</label>
								<Form.Control
									className={styleNew.file}
									type="file"
									onChange={onChange}
									required={
										item.Department_Service_Detail_Required &&
										!uploadedFiles?.length
									}
									disabled={
										item.Department_Service_File_Signature ==
											1 &&
											item.Department_Service_File_Signature_Attachment ==
											0
											? true
											: false
									}
								/>
							</div>
						</div>
					</div>

					{isUploading && (
						<div className={styleNew.files}>
							<div className="d-flex justify-content-between align-items-center px-2 py-1">
								<div
									className="d-flex flex-wrap"
									style={{ width: "150px" }}
								>
									{getFileIcon(fileUploadingData?.type)}
									<span
										onClick={handleToggleEllipsis}
										// style={{ width: '90%', maxWidth: 'unset' }}
										className={`${styleNew.fileContent}  ${!isTextExpanded
											? styleNew.clientRequestInfo
											: ""
											} mx-1`}
									>
										{fileUploadingData?.name}
									</span>
								</div>

								<div className={styleNew.progressBarContainer}>
									<ProgressBar
										value={progressValue}
										color="#D0B166"
									/>
								</div>

								<div
									className="d-flex align-items-center"
									style={{ width: "10%" }}
								>
									<span
										className={`${styleNew.fileContent} mx-1`}
									>
										{fileUploadingData?.size
											? fileUploadingData?.size + " MB "
											: null}
									</span>
								</div>
							</div>
						</div>
					)}

					{uploadedFiles?.length > 0 && (
						<div className={styleNew.files}>
							{uploadedFiles?.map((uploadedFile) => (
								<div
									key={uploadedFile?.File_Code}
									className="d-flex justify-content-between px-2 py-1"
								>
									<div
										className="d-flex flex-wrap"
										style={{ width: "calc(100% - 130px)" }}
									>
										{getFileIcon(
											uploadedFile?.File_Content
										)}
										<span
											onClick={handleToggleEllipsis}
											style={{
												width: "90%",
												maxWidth: "unset",
											}}
											className={`${styleNew.fileContent
												}  ${!isTextExpanded
													? styleNew.clientRequestInfo
													: ""
												} mx-1`}
										>
											{uploadedFile?.File_Name}
										</span>
									</div>

									<div className="d-flex align-items-center">
										<OverlayTrigger
											overlay={
												<Tooltip id="button-tooltip">
													{t("download")}
												</Tooltip>
											}
										>
											<span
												onClick={() =>
													download(uploadedFile)
												}
											>
												<svg
													width="17"
													height="18"
													viewBox="0 0 17 18"
													fill="none"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path
														d="M0.738281 13.1786V14.3725C0.738281 15.0057 0.989848 15.613 1.43764 16.0608C1.88543 16.5087 2.49277 16.7602 3.12604 16.7602H13.8709C14.5042 16.7602 15.1115 16.5087 15.5593 16.0608C16.0071 15.613 16.2587 15.0057 16.2587 14.3725V13.1786"
														stroke="#1B2124"
														strokeLinecap="round"
														strokeLinejoin="round"
													/>
													<path
														d="M5.51562 9L8.50032 11.9847L11.485 9"
														stroke="#1B2124"
														strokeLinecap="round"
														strokeLinejoin="round"
													/>
													<path
														d="M8.5 11.9847V1.23981"
														stroke="#1B2124"
														strokeLinecap="round"
														strokeLinejoin="round"
													/>
												</svg>
											</span>
										</OverlayTrigger>

										<OverlayTrigger
											overlay={
												<Tooltip id="button-tooltip">
													{t("delete")}
												</Tooltip>
											}
										>
											<span
												className="mx-2"
												onClick={() =>
													removeFile(uploadedFile)
												}
											>
												<svg
													width="14"
													height="18"
													viewBox="0 0 14 18"
													fill="none"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path
														d="M1.2513 15.7083C1.2513 16.7625 2.1138 17.625 3.16797 17.625H10.8346C11.8888 17.625 12.7513 16.7625 12.7513 15.7083V4.20833H1.2513V15.7083ZM13.7096 1.33333H10.3555L9.39714 0.375H4.60547L3.64714 1.33333H0.292969V3.25H13.7096V1.33333Z"
														fill="#E03137"
													/>
												</svg>
											</span>
										</OverlayTrigger>

										<span
											className={`${styleNew.fileContent} mx-1`}
										>
											{uploadedFile.File_Size}
										</span>
									</div>
								</div>
							))}
						</div>
					)}
				</div>

				{showErrorMsg && validatedMsg?.map(vm => {
					if (vm?.Parameter_Id == item?.Service_Detail_Id) {
						return (
							<div className={styleNew.errMsg}>
								{i18n.language == 'ar' ? vm?.Errors_AR?.map(m => <div>{m}</div>) : vm?.Errors_EN?.map(m => <div>{m}</div>)}
							</div>
						)
					}
				})}

				{console.log(validMsg)}

				{Array.isArray(validMsg) ? validMsg?.map(vm => {
					if (vm?.Parameter_Id == item?.Service_Detail_Id) {
						return (
							<div className={styleNew.errMsg}>
								{i18n.language == 'ar' ? vm?.Errors_AR?.map(m => <div>{m}</div>) : vm?.Errors_EN?.map(m => <div>{m}</div>)}
							</div>
						)
					}
				}) : (
					<div className={styleNew.errMsg}>
						{/* {i18n.language == 'ar' ? validMsg?.Errors_AR : validMsg?.Errors_EN} */}
						{validMsg}
					</div>
				)}
			</Col>

			<Col xs={12}>
				<Alert
					show={!!alertMsg}
					variant={"danger"}
					className="d-flex justify-content-between align-items-center"
					transition={true}
				>
					<span>{t("fileMaxSizeDynamic")} {item?.Department_Service_File_Max_Size} {t('fileMaxSizeDynamicMB')}</span>

					<span
						className={style.dismiss}
						onClick={() => setAlertMsg(null)}
					>
						<svg
							height="311pt"
							viewBox="0 0 311 311.07733"
							width="311pt"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path d="m16.035156 311.078125c-4.097656 0-8.195312-1.558594-11.308594-4.695313-6.25-6.25-6.25-16.382812 0-22.632812l279.0625-279.0625c6.25-6.25 16.382813-6.25 22.632813 0s6.25 16.382812 0 22.636719l-279.058594 279.058593c-3.136719 3.117188-7.234375 4.695313-11.328125 4.695313zm0 0" />
							<path d="m295.117188 311.078125c-4.097657 0-8.191407-1.558594-11.308594-4.695313l-279.082032-279.058593c-6.25-6.253907-6.25-16.386719 0-22.636719s16.382813-6.25 22.636719 0l279.058594 279.0625c6.25 6.25 6.25 16.382812 0 22.632812-3.136719 3.117188-7.230469 4.695313-11.304687 4.695313zm0 0" />
						</svg>
					</span>
				</Alert>
			</Col>

			<PopUp
				show={showModal}
				handleClose={handleClose}
				title={t("signature")}
				smallModal={true}
			>
				<Container>
					<Row>
						<Col>
							<canvas
								ref={canvasRef}
								onPointerDown={startDrawing}
								onPointerMove={draw}
								onPointerUp={stopDrawing}
								onPointerLeave={stopDrawing}
								style={{
									border: "1px dashed #00000040",
									width: "100%",
									touchAction: "none",
									borderRadius: "20px",
									marginBottom: "1rem",
								}}
							></canvas>
						</Col>
					</Row>

					<Row>
						<Col md={12}>
							<div className="d-flex justify-content-between">
								<button
									type={"button"}
									onClick={setToErase}
									className={`mainBtn mx-1 ${styleNew.stepsButton}`}
								>
									{t("eraseSignature")}
								</button>
								<button
									type={"button"}
									onClick={saveImageToLocal}
									className={`mainBtn mx-1 ${styleNew.saveButton}`}
								>
									{t("save")}
								</button>
							</div>
						</Col>
						{/* <Col sm={4} md={4} style={{ display: 'flex', justifyContent: 'end' }}>
            
            </Col> */}
					</Row>
				</Container>
			</PopUp>

			{isUploading && <LoadingOverlay />}
		</div>
	);
};

const getFileIcon = (fileType) => {
	return (
		{
			"image/jpeg": <Jpg />,
			"image/png": <Png />,
			"video/mp4": <Mp4 />,
			"application/pdf": <Pdf />,
			"application/vnd.ms-powerpoint": <Doc />,
			"application/vnd.openxmlformats-officedocument.presentationml.presentation":
				<Doc />,
			"application/msword": <Doc />,
			"application/vnd.openxmlformats-officedocument.wordprocessingml.document":
				<Doc />,
			"audio/mpeg": <Mp3 />,
			"application/zip": <Zip />,
			"text/csv": <Csv />,
			"text/plain": <Text />,
		}[fileType] ?? <Attachment />
	);
};
